.footer {
  background-color: rgb(91 100 110);
  padding: 2rem 5vw 6rem;
  text-align: center;
  .footer-icons {
    display: flex;
    justify-content: center;

    .footer-link {
      text-decoration: underline;
      color: white;
      font-size: 1.5rem;
      padding: 1rem;
    }
  }
}
