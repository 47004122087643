.contact-wrapper {
  overflow-x: hidden;
  --s: 200px; /* control the size*/
  --c1: #1a1a1a;
  --c2: #000000;

  --_g: #0000 8%, var(--c1) 0 17%, #0000 0 58%;
  background: linear-gradient(135deg, #0000 20.5%, var(--c1) 0 29.5%, #0000 0) 0
      calc(var(--s) / 4),
    linear-gradient(45deg, var(--_g)) calc(var(--s) / 2) 0,
    linear-gradient(135deg, var(--_g), var(--c1) 0 67%, #0000 0),
    linear-gradient(
      45deg,
      var(--_g),
      var(--c1) 0 67%,
      #0000 0 83%,
      var(--c1) 0 92%,
      #0000 0
    ),
    var(--c2);
  background-size: var(--s) var(--s);
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  .width-restrict {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      width: clamp(0px, 100%, 600px);
    }
  }
}

form {
  width: clamp(0px, 100%, 600px);

  .form-elements {
    border-radius: 1rem;
    background-color: white;
    overflow: hidden;
    position: relative;

    input,
    textarea {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      border: none;

      &:focus {
        outline: none;
      }
    }

    input {
      border-bottom: 1px solid lightgray;
    }

    textarea {
      height: 10rem;
    }

    .element {
      display: flex;
      position: relative;

      p {
        color: black;
        position: absolute;
        margin: 1rem;
        font-size: 12px;
        top: 0;
        right: 0;

        &:last-child {
          top: unset;
          bottom: 0;
        }
      }
    }
  }

  .send-button {
    padding: 1rem;
    font-size: medium;
    background-color: white;
    border-radius: 1rem;
    margin-top: 1rem;
    width: 100%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    width: clamp(0px, 100%, 1000px);

    &.loading {
      cursor: not-allowed;
      background-color: lightgray;
      position: relative;
      color: black;
    }

    &.success {
      background-color: green;
      color: white;
    }

    &.error {
      background-color: red;
      color: white;
    }
  }
}

.mountain-transition {
  width: 150vw;
  height: 100%;
}
