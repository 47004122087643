@import "../styles/colors";

.dots {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: radial-gradient(#535353 5%, transparent 0);
  background-size: clamp(35px, 7vw, 55px) clamp(35px, 7vw, 55px);
  z-index: -2;
}

.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  .main-title {
    height: 105svh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .width-restrict {
      height: 100%;
      max-width: 1200px;
    }
  }

  .heading {
    padding-top: 10rem;
    h1 {
      margin: 0;
      font-size: clamp(50px, 23.5vw, 170px);
    }
    @-moz-document url-prefix() {
      h1 {
        font-size: clamp(50px, 22.5vw, 170px);
      }
    }

    h1:first-child {
      margin-bottom: -0.5rem;
    }
    p {
      font-size: clamp(0.5rem, 6vw, 2.2rem);
      margin-top: 0.5rem;
    }
    .location-text {
      font-size: clamp(1rem, 4vw, 1.5rem);
      margin: 0rem;
    }
  }
}

.wave-container {
  overflow: hidden;
  width: 100%;
  .wave {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    height: 105svh;
    width: 100%;
    margin: 0;
    transform: translate(0, 1px);

    svg {
      bottom: -1px;
      position: absolute;
      height: 100%;
      left: -400px;

      @media (min-width: 500px) {
        height: 75%;
      }
    }
    .blue-volume {
      background: linear-gradient(to top, #111 50%, rgb(17, 63, 112));
      height: 70vh;
      width: 1000vw;
      position: absolute;
      top: 105svh;
    }
  }
}

.curve-sep {
  height: 80px;
  top: -1rem;
  background-color: rgb(17, 63, 112);
  transform: rotate(180deg);
  fill: #111;
  margin: 0;
  overflow: hidden;
}

.about-banner-link {
  width: 100vw;
  margin: 7rem 0;
  text-decoration: none;
  .about-banner-margin {
    margin-left: auto;
    width: clamp(23rem, 70vw, 70rem);
    background-color: rgb(17, 63, 112);
    border-radius: 10rem 0 0 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      text-decoration: none;
      padding: 1rem 0 1rem 1rem;
      font-size: clamp(0.5rem, 6vw, 1.6rem);
    }
    svg {
      height: 2.25rem;
      width: 2.25rem;
      margin-bottom: -4px;
      color: white;
      transition: 0.2s ease-out;
    }
  }
  &:hover {
    svg {
      transform: translateX(10px);
    }
  }
}
