@import "../styles/colors";

.navbar-container {
  position: fixed;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  height: 60px;
  width: 100vw;
  z-index: 98;
}

.navbar-width {
  width: 90vw;
  max-width: 1200px;
  height: 60px;
  overflow: hidden;
}

.navbar,
.navbar-mobile {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar-big-pill {
  font-size: 1.4rem;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  .hamburger,
  .cross {
    padding: 1rem 0 1rem 1rem;
  }
}

.navbar-left {
  margin-right: auto;
  padding: 10px;
  text-decoration: none;
  color: white;
  z-index: 100;
  p {
    margin: 0;
    margin-top: -2px;
  }
}

.navbar-right {
  padding: 10px 10px;
  text-decoration: none;
  color: white;
  margin-top: -5px;
}

.navbar-mobile {
  display: none;
  .nav-mobile {
    color: white;
    text-decoration: none;
    font-size: 15vw;
  }
  .cross {
    z-index: 100;
  }
  .dropdown {
    z-index: 99;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      padding: 0.5rem;
    }
  }
  .hidden {
    display: none;
  }
  .nav-mobile.active {
    text-decoration: underline;
  }
  .navbar-left {
    padding-left: 6.25px;
  }
}
.navbar-right.active {
  text-decoration: underline;
}

.hidden {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
}
