.contact-button {
  position: fixed;
  bottom: clamp(10px, 5vw, 50px);
  right: clamp(10px, 5vw, 50px);
  z-index: 10;
  button {
    padding-top: 10px;
    padding-left: 1rem;
    color: black;
    background-color: white;
    font-size: clamp(0.5rem, 6vw, 1.6rem);
    svg {
      height: 2.25rem;
      width: 2.25rem;
      margin-bottom: -10px;
      animation: moveIcon 0.5s infinite alternate ease-out;
    }
  }
}
@keyframes moveIcon {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}
