@import "../styles/colors";

html {
  background-color: $background;
  color: white;
}

button {
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  border: none;
  font-size: 1.75rem;
}

.container {
  width: 100wv;
  display: flex;
  justify-content: center;
}

.width-restrict {
  width: 90vw;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #222222;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: lighten(#222222, 10%);
}

@font-face {
  font-family: "Helvetica";
  src: url("../assets/Helvetica\ Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
