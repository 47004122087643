@import "colors";

.projects-container {
  margin: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  svg {
    fill: rgb(31, 87, 146);
    height: 2%;
  }
}

.projects {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #111;
    border-radius: 1rem;
    .content {
      width: 100%;
      max-width: 1200px;

      h1 {
        font-size: clamp(2rem, 10vw, 3.5rem);
        text-align: center;
        margin-bottom: 6rem;
        margin-top: 5.5rem;
      }
      .project {
        position: relative;
        display: flex;
        flex-direction: column-reverse;

        @media screen and (min-width: 800px) {
          flex-direction: row-reverse;
          justify-content: space-between;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: radial-gradient(
            circle at top,
            transparent 10%,
            #111 clamp(30%, 50vw, 50%)
          );
          pointer-events: none; /* Allows clicks to pass through */
        }

        &:nth-child(2) {
          --c: #21333d;
        }
        &:nth-child(3) {
          --c: #4a1b15;
        }
        &:nth-child(4) {
          --c: #243c1a;
        }

        width: 100%;
        background-color: var(--c);
        box-sizing: border-box;
        overflow: hidden;
        text-decoration: none;
        color: white;

        .project-svg-container {
          padding: clamp(0.5rem, 5vw, 2rem);
          height: 150vw;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          @media screen and (min-width: 800px) {
            margin-right: clamp(0px, 3vw, 500px);
            height: 850px;
            width: 500px;
          }

          @media screen and (min-width: 1200px) {
            margin-right: 0;
          }

          svg,
          img {
            width: 100%;
            top: 0;
            position: absolute;
            z-index: 1;
          }
        }
        .text {
          max-width: 90vw;
          border-radius: 2rem;
          font-size: clamp(8px, 4.1vw, 20px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: clamp(1rem, 10vw, 4rem) clamp(0.5rem, 5vw, 2rem);
          flex: 1;

          @media screen and (min-width: 800px) {
            margin-left: clamp(0px, 3vw, 500px);
          }

          @media screen and (min-width: 1200px) {
            margin-left: 0;
          }

          h2 {
            margin: 0;
            font-size: clamp(1rem, 9vw, 3.5rem);
            z-index: 1;
          }

          @-moz-document url-prefix() {
            h2 {
              font-size: clamp(1rem, 8.5vw, 3.5rem); /* Slight adjustment */
            }
          }

          p {
            margin: 0;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            max-width: 50ch;
            margin-right: auto;
            z-index: 1;
          }

          .date {
            display: flex;

            svg {
              height: 30px;
              width: 30px;
              margin-top: 0.3rem;
              margin-left: 0.5rem;
              z-index: 1;
            }
            p {
              width: fit-content;
              margin: 0.5rem;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
