.about-wrapper {
  overflow-x: hidden;
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40vh 0;
    margin: 0;
  }
}
